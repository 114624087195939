import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";

function Footer({ GlobalStore: { site } }) {
  const date = new Date();
  const termsDoc = _.find(site.docs, ["name", "terms"]);
  return (
    <footer>
      <div className="copyright">
        Copyright {date.getFullYear()} © &nbsp;
        {termsDoc && (
          <a
            href={termsDoc.url}
            target="_blank"
            rel="noopener noreferrer"
            alt="Aviso de Privacidad, Términos y Condiciones
          de Uso"
          >
            Términos y Condiciones
          </a>
        )}
      </div>
    </footer>
  );
}

export default inject("GlobalStore")(observer(Footer));
