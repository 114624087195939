import axios from "axios";
import Cookies from "js-cookie";

import { observable, action, decorate, runInAction } from "mobx";
import endpoint from "../config/endpoint";

//firebase storage
import { storage } from "../firebase";

//helpers
import handleDataLayer, {
  dataLayerSubmissionReq,
} from "./helpers/handleDataLayer";

import getUTM from "./helpers/getUTM";
import getRecaptchaToken from "./helpers/getRecaptchaToken";
import syncLocalStorage from "./helpers/syncLocalStorage";
import setBrowser from "./helpers/setBrowser";
import setGoogle from "./helpers/setGoogle";
import handleDocs from "./helpers/handleDocs";
import handleStyles from "./helpers/handleStyles";
//import handleImgs from "./helpers/handleImgs";

class GlobalStore {
  loading = {
    data: true,
    server: true,
  };
  err = false;
  msg = null;
  step = 1;
  config = {
    showAcceptTerms: true,
    showValidateAddress: true,
    showFinalScreen: false,
  };
  session = {};
  browser = {};
  ads = {};
  utm = {};
  tracking = {
    addressType: null,
  };
  definition = [];
  formik = null;
  isLastStep = false;
  finalScreen = null;
  site = {};

  constructor() {
    this.handleDataLayer = handleDataLayer;
    this.site = this.getSiteData();
    this.utm = getUTM();
    this.session = syncLocalStorage();
    this.browser = setBrowser();
    this.ads = setGoogle();
    this.init();
  }

  setFormik(formik) {
    return (this.formik = formik);
  }
  setTracking(obj) {
    return (this.tracking = obj);
  }
  setStep(newStep) {
    return (this.step = newStep);
  }

  async getSiteData() {
    try {
      const downloadURL = await storage.ref("/config.json").getDownloadURL();
      const response = await axios.get(downloadURL);
      const docs = response.data.docs
        ? await handleDocs(response.data.docs)
        : null;
      const styles = response.data.styles
        ? await handleStyles(response.data.styles)
        : null;
      runInAction(() => {
        this.site = {
          docs,
          styles,
        };
        this.loading.data = false;
      });
    } catch (error) {
      return null;
    }
  }

  async init() {
    this.session.leadId = this.utm.leadId || this.session.leadId; //set LeadId from queryString
    try {
      const response = await axios.post(`${endpoint}/init`, {
        ads: this.ads,
        browser: this.browser,
        session: this.session,
        utm: this.utm,
      });
      if (response.data.dataLayer) {
        this.handleDataLayer(response.data.dataLayer);
      }
      if (response.data.status === "OK") {
        const { browser, ads, utm, session, definition } = response.data;
        localStorage.setItem("deviceId", session.deviceId);
        localStorage.setItem("requestId", session.requestId);
        runInAction(() => {
          this.browser = browser;
          this.ads = ads;
          this.utm = utm;
          this.session = session;
          this.definition = definition;
          this.loading.server = false;
        });
      }
      if (response.data.status === "ERR") {
        return this.handleErrFromServer(response.data.msg);
      }
    } catch (error) {
      return this.handleErrFromServer(error);
    }
  }

  async handleSubmit() {
    dataLayerSubmissionReq({
      step: this.step,
      values: this.formik.values,
      sessionId: this.session.sessionId,
      deviceId: this.session.deviceId,
    });
    try {
      const response = await axios.post(`${endpoint}/step${this.step}`, {
        browser: this.browser,
        session: this.session,
        values: this.formik.values,
        tracking: this.tracking,
        recaptchaToken: await getRecaptchaToken(),
      });
      if (response.data.dataLayer) {
        this.handleDataLayer(response.data.dataLayer);
      }
      if (response.data.status === "OK") {
        if (response.data.step === "FINAL") {
          return runInAction(() => {
            this.config = {
              ...this.config,
              showFinalScreen: true,
            };
            this.finalScreen = response.data.finalScreen;
          });
        }
        runInAction(() => {
          this.session = response.data.session || this.session;
          this.isLastStep = response.data.isLastStep || this.isLastStep;
          this.definition = response.data.definition || this.definition;
        });
        if (!this.isLastStep) {
          this.setStep(this.step + 1);
        }
      }
      if (response.data.status === "ERR") {
        return this.handleErrFromServer(response.data.msg);
      }
    } catch (error) {
      return this.handleErrFromServer(error);
    }
  }

  async setGaClientId() {
    const cookie = Cookies.get("ga_client_id");
    localStorage.setItem("ga_client_id", cookie);
    runInAction(() => {
      this.session = {
        ...this.session,
        gaClientId: cookie,
      };
    });
    try {
      const response = await axios.post(`${endpoint}/setGaClientId`, {
        gaClientId: cookie,
        requestId: this.session.requestId,
        sessionId: this.session.sessionId,
        requestHostname: this.browser.requestHostname,
      });
      if (response.data.dataLayer) {
        this.handleDataLayer(response.data.dataLayer);
      }
    } catch (error) {
      return this.handleErrFromServer(error);
    }
  }

  setFlashMsg(msg) {
    this.msg = msg;
    setTimeout(() => {
      this.msg = null;
    }, 5000);
  }

  handleErrFromServer(msg) {
    runInAction(() => {
      this.err = true;
      this.msg = msg;
      this.loading = {
        data: false,
        server: false,
      };
    });
  }
}
decorate(GlobalStore, {
  loading: observable,
  err: observable,
  msg: observable,
  site: observable,
  step: observable,
  showFinalScreen: observable,
  finalScreen: observable,
  config: observable,
  session: observable,
  tracking: observable,
  definition: observable,
  isLastStep: observable,
  setFlashMsg: action.bound,
  getSiteData: action.bound,
  handleSubmit: action.bound,
  handleDataLayer: action.bound,
  setFormik: action.bound,
  setTracking: action.bound,
  setStep: action.bound,
  setGaClientId: action.bound,
  formik: observable,
});

const globalStore = new GlobalStore();

export default globalStore;
